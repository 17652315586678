import { createRouter, createWebHistory } from 'vue-router';
import HomeView from './views/HomeView.vue';
import PrivacyPolicy from './views/PrivacyPolicy.vue';

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView
    },
    {
        path: '/privacypolicy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy
    },
    {
        path: '/deleteaccount',
        name: 'DeleteAccount',
        component: () => import('./views/DeleteView.vue')
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;