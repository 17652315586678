import { createApp } from 'vue';
import App from './App.vue';
import BaseContainer from './components/BaseContainer.vue';
import BaseButton from './components/BaseButton';
import AboutStep from './components/AboutStep.vue';
import router from './router';

import './styles/global.css';
import './styles/utils.css';

const app = createApp(App)
    .use(router);
app.component('base-container', BaseContainer);
app.component('base-button', BaseButton);
app.component('AboutStep', AboutStep);

app.mount('#app');