<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<style scoped>
.container {
  padding: 0 15px;
  max-width: 1280px;
  margin: 0 auto;
}
</style>