<script setup lang="js">
import { defineProps } from 'vue';
import { ref, onMounted, onUnmounted } from 'vue';

const props = defineProps({
    flip: Boolean,
    title: String,
    desc: String,
    img: String
});

const isFlipped = ref(props.flip);

const updateFlip = () => {
    console.log(window.innerWidth);
    isFlipped.value = window.innerWidth >= 992 ? props.flip : true;
};

onMounted(() => {
    window.addEventListener('resize', updateFlip);
    updateFlip();
});

onUnmounted(() => {
    window.removeEventListener('resize', updateFlip);
});

</script>

<template>
    <div class="mb5">
        <div class="util-container">
            <div v-if="!isFlipped" class="col--12 col--lg-6">
                <figure class="about-card__figure">
                    <img :src="props.img" alt="">
                </figure>
            </div>
            <div class="col--12 col--lg-6">
                <div class="about-card__text">
                    <h2 class="about-card__title">{{ props.title }}</h2>
                    <p class="about-card__desc">{{ props.desc }}</p>
                </div>
            </div>
            <div v-if="isFlipped" class="col--12 col--lg-6">
                <figure class="about-card__figure">
                    <img :src="props.img" alt="">
                </figure>
            </div>
        </div>
    </div>
</template>

<style scoped>

.about-card {
    margin-bottom: 5rem;
}
  
  .about-card__text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .about-card__title {
    text-align: left;
    font-size: 1.5rem;
    color: var(--blue-900);
    margin-bottom: 2rem;
  }
  
  .about-card__desc {
    font-size: 1rem;
    line-height: 200%;
    color: var(--gray-700);
  }
  
  .about-card__figure {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media screen and (min-width: 992px) {
    .about-card__figure {
      margin-bottom: 0;
    }
  
    .about-card__title {
      font-size: 1.8rem;
    }
  
    .about-card__desc {
      font-size: 1.25rem;
    }
  }
</style>