<template>
    <section class="hero-sec">
        <div class="hero-sec__inner">
            <base-container>
                <div class="util-container">
                    <div class="hero-sec__content w-half">
                        <img class="movingCart" src="../../assets/TransparentMovingCart.gif" alt="people working">
                        <h1 class="hero-sec__title">
                            Effortless store navigation made simple...
                        </h1>
                        <p class="hero-sec__desc">
                            The ultimate grocery shopping companion app. Collaborative list-building and store-specific
                            aisle navigation ensure
                            you always find your items with ease. Say goodbye to aimless searching - save time, reduce
                            frustration, and enhance
                            your grocery journey.
                        </p>
                        <p class="hero-sec__desc">
                            Download Speed Grocer on iOS and Android today!
                        </p>
                        <base-button link href="https://apps.apple.com/us/app/speed-grocer/id6475634115" class="btn btn--outline-white btn--large mb2 marginInFull">
                            App Store
                        </base-button>
                        <br>
                        <base-button link href="https://play.google.com/store/apps/details?id=com.candcventures.speedgrocer&hl=en_US" class="btn btn--outline-white btn--large mb2">
                            Google Play Store
                        </base-button>
                    </div>
                    <div class="w-half ma">
                        <!-- <img class="d-none d-lg-block" src="../../assets/hero.png" alt="people working"> -->
                        <img class="iphoneImage" src="../../assets/AppOniPhone.png" alt="people working">
                    </div>
                </div>
            </base-container>
        </div>
    </section>
</template>

<style scoped>

.movingCart {
    background-color: white;
    border-radius: 1rem;
    height: 6vh;
}

.btn {
    width: 50%;
}

.iphoneImage {
    height: 60vh;
}

.hero-sec {
    padding-top: 4rem;
    color: var(--white);
    position: relative;
    margin-bottom: 1rem;
}

.hero-sec__inner {
    background-color: var(--primary);
    padding-top: 2rem;
}

.hero-sec::after {
    content: "";
    display: block;
    height: 8rem;
    background: url('../../assets/curve-blue.svg') bottom/cover no-repeat;
}

.hero-sec__content {
    width: 100%;
    text-align: center;
}

.hero-sec__title {
    font-size: 2rem;
    line-height: 130%;
}

.hero-sec__desc {
    font-size: 1rem;
    line-height: 170%;
    margin-bottom: 2.5rem;
}

.marginInFull {
    margin-right: 0rem;
}

@media screen and (min-width: 992px) {
    .marginInFull {
        margin-right: 2rem;
    }

    .hero-sec {
        padding-top: 5rem;
        margin-bottom: 2rem;
    }

    .hero-sec__inner {
        display: flex;
        align-items: center;
        min-height: calc(100vh - 5rem);
    }

    .hero-sec__content {
        text-align: left;
    }

    .hero-sec__title {
        font-size: 3rem;
        line-height: 130%;
        max-width: 80%;
    }

    .hero-sec__desc {
        text-align: justify;
        font-size: 1.25rem;
    }

    .hero-sec::after {
        height: 13rem;
    }
}
</style>