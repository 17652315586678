<!-- src/App.vue -->
<template>
    <div id="app">
      <!-- <nav>
        <router-link to="/">Home</router-link>
        <router-link to="/privacy-policy">Privacy Policy</router-link>
      </nav> -->
      <router-view></router-view>
    </div>
  </template>
  
  <script setup lang="js">

  </script>