<template>
    <div class="container">
        <h1>Privacy Policy</h1>
        <p>Last updated: 09/12/2024</p>

        <h2>Introduction</h2>
        <p>Welcome to Speed Grocer. We value your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we handle your personal data when you use our app.</p>

        <h2>Data Collection</h2>
        <p>We may collect and process the following data about you:</p>
        <ul>
            <li>Personal identification information (Name, email address, phone number, etc.)</li>
            <li>Usage data (how you use our app)</li>
            <li>Technical data (IP address, browser type, etc.)</li>
        </ul>

        <h2>How We Use Your Data</h2>
        <p>We use your data to:</p>
        <ul>
            <li>Provide and maintain our service</li>
            <li>Notify you about changes to our service</li>
            <li>Provide customer support</li>
            <li>Gather analysis or valuable information so that we can improve our service</li>
            <li>Monitor the usage of our service</li>
        </ul>

        <h2>Data Sharing</h2>
        <p>We do not share your personal data with third parties except in the following cases:</p>
        <ul>
            <li>With your consent</li>
            <li>For external processing (e.g., with service providers)</li>
            <li>For legal reasons (e.g., to comply with legal obligations)</li>
        </ul>

        <h2>Your Rights</h2>
        <p>You have the following rights regarding your personal data:</p>
        <ul>
            <li>The right to access – You have the right to request copies of your personal data.</li>
            <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate.</li>
            <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
            <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
            <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
            <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
        </ul>

        <h2>Contact Us</h2>
        <p>If you have any questions about this privacy policy, please contact us at:</p>
        <p>Email: officialcandcventures@gmail.com</p>
    </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
};
</script>

<style scoped>

.container {
            max-width: 800px;
            margin: 2rem auto;
            padding: 1rem;
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        h1, h2, h3 {
            color: #333;
        }
        p {
            margin: 1rem 0;
        }
</style>